import React, { useState } from 'react';
import Title from '../../components/Texts/Title';
import { useIntl } from 'react-intl';
import Column from '../../layouts/Column';
import Paragraph from '../../components/Texts/Paragraph';
import CookiesModalTable from './CookiesModalTable';
import Image from '../../components/Media/Image';

import './cookies-modal-table.less';


const CookiesModalAccordeon = (props) => {
    const intl = useIntl();

    const {cookies, title, index, setCookiesConsented, cookiesConsented} = props;
    const [content, setContent] = useState(false);
    const [url, setUrl] = useState("/images/cookies/up.webp");

    const textId = ["cookies.modal.table.text.analytics", "cookies.modal.table.text.preferences", "cookies.modal.table.text.marketing", "cookies.modal.table.text.mandatory"];

    const changeTableContent = () => {
        setContent(!content);
        setUrl(content ? "/images/cookies/up.webp" : "/images/cookies/down.webp");
    }

    const changeCookiesAnalytics = () => {
        setCookiesConsented({mandatory: true, analytics: !cookiesConsented.analytics, preferences: cookiesConsented.preferences, marketing: cookiesConsented.marketing});
    }

    const changeCookiesPreferences = () => {
        setCookiesConsented({mandatory: true, analytics: cookiesConsented.analytics, preferences: !cookiesConsented.preferences, marketing: cookiesConsented.marketing});
    }

    const changeCookiesMarketing = () => {
        setCookiesConsented({mandatory: true, analytics: cookiesConsented.analytics, preferences: cookiesConsented.preferences, marketing: !cookiesConsented.marketing});
    }

    return <Column className='cookies-table-accordeon-container'>
        <Column className='cookies-table-accordeon-content'> 
            <Title depth={4}>{intl.formatMessage({ id: title})}</Title>
            {index == 0 && <input className="cookies-checkbox" type="checkbox" onClick={(e) => {e.stopPropagation(); changeCookiesAnalytics()}}></input>}
            {index == 1 && <input className="cookies-checkbox" type="checkbox" onClick={(e) => {e.stopPropagation(); changeCookiesPreferences()}}></input>}
            {index == 2 && <input className="cookies-checkbox" type="checkbox" onClick={(e) => {e.stopPropagation(); changeCookiesMarketing()}}></input>}
            <span onClick={changeTableContent} className="accordeon-display-arrow">
                <Image width="35px" source={url} alt="arrow"/>
            </span>
        </Column>
        {content == true && (
            <Column>
                <Paragraph>{intl.formatMessage({ id: textId[index]})}</Paragraph>
                {cookies.length > 0 && <CookiesModalTable cookies={cookies}/>}
            </Column>
        )}
    </Column>
}

export default CookiesModalAccordeon;