import React, {useState, useEffect} from "react";
import CookiesWindow from './CookiesWindow'
import { getCookie } from "../../../utils/cookiesUtils";
import { setCookie } from "../../../utils/cookiesUtils";
import cookies from '../../../modules/cookies';
import {useSelector} from 'react-redux';

const CookieManager = () => {
    const [display, setDisplay] = useState(false);

    const cookiesMandatory = useSelector(cookies.selectors.getMandatoryCookiesList);
    const cookiesAnalytics = useSelector(cookies.selectors.getAnalyticsCookiesList);
    const cookiesPreferences = useSelector(cookies.selectors.getPreferencesCookiesList);
    const cookiesMarketing = useSelector(cookies.selectors.getMarketingCookiesList);

    if ((getCookie("cookieAccepted") === "" && getCookie("analyticsAccepted") === "" && getCookie("preferencesAccepted") === "" && getCookie("marketingAccepted") === "" )) {
        setCookie("cookiesAccepted", false, 30000);
        setCookie("analyticsAccepted", false, 30000);
        setCookie("preferencesAccepted", false, 30000);
        setCookie("marketingAccepted", false, 30000);
    }

    const setCookiesList = () =>{
        let arr1 = [], arr2 = []; let arr3 = []; let arr4 = [];
        cookiesAnalytics.map((cookie) =>{
            arr1.push(cookie);
        });
        cookiesPreferences.map((cookie) =>{
            arr2.push(cookie);
        });
        cookiesMarketing.map((cookie) =>{
            arr3.push(cookie);
        });
        cookiesMandatory.map((cookie) =>{
            arr4.push(cookie);
        });
        let cookiesList = [arr1, arr2, arr3, arr4];
        return cookiesList;
    }

    useEffect(() => {
        if(getCookie("cookiesAccepted") === "true"){
            setDisplay(true);
        }
    }, [display]);

    return <React.Fragment>
        {display == false && (<CookiesWindow cookiesList={setCookiesList()} setDisplay={setDisplay}/>)}
    </React.Fragment>
}

export default CookieManager;